#cookie-notice {
    body.has-cookie-script & {
        display: none;
    }

    .cookie-notice-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 40px;

        @include media(S) {
            flex-direction: row;
        }
    }

    .cn-text-container {
        margin: 0;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    .cn-buttons-container {
        margin: 8px 0 0;

        @include media(S) {
            margin: 0 16px;
        }
    }

    .cn-button {
        @extend %button--tertiary;

        min-height: 0 !important;
        padding: 4px 16px !important;
        font-size: 1.4rem !important;
    }

    .cn-close-icon {
        &::before,
        &::after {
            background-color: white;
        }
    }
}