html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 62.5%;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

body {
    line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
    display: block;
}

ol,
ul {
    list-style: none;
}

table {
    border-collapse: separate;
    border-spacing: 0;
}

caption,
th,
td {
    font-weight: normal;
    text-align: left;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

blockquote,
q {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    quotes: none;
}

a:focus {
    outline: 2px solid #c1c1c1;
    outline: 2px solid rgba(51, 51, 51, 0.3);
}

a:hover,
a:active {
    outline: 0;
}

a img {
    border: 0;
}
