#cookiescript_injected,
#cookiescript_injected_fsd {
    z-index: $z-CookieScript__Popup !important;

    #cookiescript_wrapper {
        width: calc(100vw - 32px);
        max-width: 610px;
        padding: 16px;
        max-height: 90vh;
        overflow-x: hidden;
        font-family: $base-font;
        border-radius: 4px;
        background: white;

        @include media(M) {
            padding: 24px;
        }
    }

    #cookiescript_close {
        top: 2px;
        right: 2px;
        padding: 11px;
        width: 37px;
        height: 37px;
        color: transparent;
        border-radius: 50%;
        background: transparent;
        opacity: 1;

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: $red-5;
            opacity: 0;
            transition: opacity $transition;
        }

        &::after {
            content: '×';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            color: $base-color;
            line-height: 37px;
            transition: color $transition;
        }

        &:hover {
            &::before {
                opacity: 1;
            }

            &::after {
                color: $red;
            }
        }
    }

    #cookiescript_header {
        margin: 0 auto;
        padding: 20px 0 8px;
        font-size: 2rem;
        font-weight: 700;

        @include media(L) {
            padding: 20px 0 16px;
            font-size: 2.4rem;
        }
    }

    #cookiescript_reportwrap,
    #cookiescript_iabwrap,
    #cookiescript_aboutwrap {
        &::-webkit-scrollbar-track {
            background: $red-5;
        }
    }

    .cookiescript_checkbox_label {
        flex-wrap: nowrap;
    }

    #cookiescript_description {
        color: $base-color;

        a {
            color: $red;
            text-decoration: underline;
            white-space: nowrap;

            &:hover {
                text-decoration: none;
            }
        }
    }

    #cookiescript_aboutwrap,
    .cookiescript_category_description {
        color: $base-color;
    }

    .cookiescript_fullreport {
        th,
        td {
            color: $base-color;
        }

        th {
            background: $red-5;

            &:first-child {
                border-top-left-radius: 4px;
            }

            &:last-child {
                border-top-right-radius: 4px;
            }
        }

        tr:nth-child(even) {
            @media only screen and (max-width: 414px) {
                background: $red-5;
            }
        }
    }

    .cookiescript_vendor_name {
        a {
            color: $base-color;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .cookiescript_checkbox_text {
        text-transform: none;
    }

    .cookiescript_checkbox:hover {
        .mdc-checkbox__native-control {
            &:enabled:not(:checked):not(:indeterminate) {
                ~ .mdc-checkbox__background {
                    border-color: $red;
                }
            }
        }
    }

    .mdc-checkbox {
        flex: 0 0 20px !important;
        width: 20px !important;
        height: 20px !important;

        .mdc-checkbox {
            &__background {
                width: 20px;
                height: 20px;
                border-width: 1px;
                border-radius: 4px;

                &::before {
                    top: -11px !important;
                    left: -11px !important;
                    width: 40px !important;
                    height: 40px !important;
                }

                svg {
                    display: none;
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    top: 3px;
                    left: 3px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-image: url('#{$assetsPath}/img/check--white.svg');
                    opacity: 0;
                    transition: opacity .3s ease-in-out;
                }
            }

            &__native-control {
                width: 44px !important;
                height: 44px !important;

                &:enabled:not(:checked):not(:indeterminate) {
                    ~ .mdc-checkbox__background {
                        border-color: $base-color;
                    }
                }

                &:enabled:checked,
                &:enabled:indeterminate {
                    ~ .mdc-checkbox__background {
                        border-color: $red;
                        background-color: $red;

                        &::after {
                            opacity: 1;
                        }
                    }
                }

                &:disabled:checked {
                    ~ .mdc-checkbox__background {
                        &::after {
                            opacity: 1;
                        }
                    }
                }

                &:indeterminate,
                &:checked {
                    ~ .mdc-checkbox__background {
                        &::before {
                            background-color: $red !important;
                        }
                    }
                }

                &:focus {
                    ~ .mdc-checkbox__background {
                        &::before {
                            opacity: .06;
                        }
                    }
                }
            }
        }
    }

    #cookiescript_buttons {

        #cookiescript_accept,
        #cookiescript_reject,
        #cookiescript_save {
            margin: 4px !important;
            text-transform: none;
        }

        #cookiescript_accept,
        #cookiescript_save {
            @extend %button--primary;
        }

        #cookiescript_reject {
            @extend %button--secondary;
        }
    }

    #cookiescript_manage {
        &:hover {
            color: $red;
        }
    }

    #cookiescript_cookietablewrap:not(.cookiescript_hidden) {
        min-height: 218px;
    }

    #cookiescript_maintabs {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background: transparent;

        > div {
            color: $base-color;
            font-weight: $bold;
            text-transform: none;
            border: none;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background: white;

            &:hover {
                background: $red-5;
            }

            &.cookiescript_active {
                color: white;
                border-color: $red;
                background: $red;
            }
        }
    }

    #cookiescript_categories,
    #cookiescript_iab_type {
        > div {
            color: $grey-90;
            border: 1px solid $grey-40;
            border-radius: 4px;
            background: transparent;

            &:not(.cookiescript_active):hover {
                color: $grey-90;
                border-color: $grey-90;
                background: transparent;
            }

            &.cookiescript_active {
                color: white;
                border-color: $grey-90;
                background: $grey-90;
            }
        }
    }

    #cookiescript_reportdate {
        display: none !important;
    }

    #cookiescript_tabscontent {
        border-radius: 0;
    }
}

#cookiescript_badge {
    z-index: $z-CookieScript__Badge !important;

    &:hover {
        background-color: $red;

        .cookiescriptlogo {
            fill: white;
        }

        #cookiescript_badgetext {
            color: white;
        }

        @media (hover: none) {
            background-color: white;

            .cookiescriptlogo {
                fill: $red;
            }

            #cookiescript_badgetext {
                color: $base-color;
            }
        }
    }

    .cookiescriptlogo {
        transition-duration: 500ms;
    }

    #cookiescript_badgetext {
        text-transform: none;
        font-size: 1.4rem;
        line-height: 2.8;
    }
}