* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    color: $base-color;
    font-family: $base-font;
    font-size: 1.6rem;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    &.error-bg {
        background-color: #f1f1f1;
    }
}

a {
    color: $base-color;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

sup {
    vertical-align: super;
    font-size: 1.4rem;
}

sub {
    vertical-align: sub;
    font-size: 1.4rem;
}

// Used for full page overlays
.overflow-hidden {
    overflow: hidden;
    position: relative;
}

// Stolen with pride from bootstrap
.sr-only {
    @extend %sr-only;
}
